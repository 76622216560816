<template>
  <v-app>
    <v-main>
      <v-card
        data-error="notfound"
        flat
        tile
        max-width="1320"
        class="mx-auto px-16"
      >
        <v-container>
          <v-row dense no-gutters>
            <v-col>
              <v-img src="@/assets/logo404.svg" />
            </v-col>
            <v-col>
              <v-card flat tile width="100%">
                <div class="text-overline">
                  {{ $t("404.title") }}
                </div>
                <div class="cantata-one text-h1 grey--text text--lighten-2">
                  404
                </div>
                <div class="cantata-one text-h4 py-8">
                  {{ $t("404.headline") }}
                </div>
                <p class="text-body-1">
                  <i18n path="404.feedback">
                    <template #home>
                      <router-link
                        class="accent--text"
                        :to="{ name: 'Home' }"
                        >{{ $t("home") }}</router-link
                      >
                    </template>
                  </i18n>
                </p>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-main>
  </v-app>
</template>

<style scoped>
.cantata-one {
  font-family: "Cantata One", serif !important;
}
</style>

<script>
export default {
  name: "NotFound",
};
</script>
